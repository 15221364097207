export default {
  name: "Error-1",
  mounted() {},
  methods: {
    navigateToDefault() {
      this.$router.push({ name: "dashboard" });
    }
  },
  computed: {
    backgroundImage() {
      return (
        process.env.BASE_URL +
        "assets/media/bg/public_static_img_illustration.jpg"
      );
    }
  }
};
